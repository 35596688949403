<div
  class="bg-white-primary border rounded border-gray-light dark:border-gray-dark dark:bg-black-secondary"
>
  <div class="modal-content">
    <div class="p-6 modal-body flex flex-col justify-center items-center">
      <div
        class="p-[9px] mr-3 {{
          neutral ? 'bg-green-secondary' : 'bg-red-primary'
        }} bg-opacity-[0.07] rounded-full w-12 h-12 flex items-center justify-center mb-5"
      >
        <svg-icon
          [ngClass]="{ 'red-icon': !neutral }"
          src="assets/icons/{{ neutral ? 'check' : 'warning-2' }}.svg"
        ></svg-icon>
      </div>
      <h2 class="text-base font-semibold dark:text-white-primary mb-2">
        {{ title }}
      </h2>
      <p class="text-sm dark:text-white-primary leading-5 flex">
        @if (icon) {
          <svg-icon
            [svgStyle]="{
              width: '20px',
              height: '20px'
            }"
            [src]="icon"
            class="mr-4 yellow-icon"
          ></svg-icon>
        }
        {{ description }}
      </p>
    </div>

    <div class="flex items-center gap-4 justify-center p-6 modal-footer">
      <button
        appRipple
        (click)="ref.close(false)"
        class="w-[170px] py-2.5 flex items-center justify-center px-4 hover:bg-gray-hover rounded-lg outline-none cursor-pointer border border-gray-light dark:border-gray-dark"
      >
        <!-- class="w-[170px] py-2.5 mr-2 hover:bg-gray-hover text-sm leading-5 rounded-lg outline-none cursor-pointer border border-black-primary text-black-1 dark:text-white-primary" -->
        <span
          class="capitlize leading-4 font-medium text-sm capitalize dark:text-white-primary text-black-1"
        >
          {{ cancelText }}</span
        >
      </button>
      <button
        appRipple
        (click)="ref.close(true)"
        class="w-[170px] relative flex border py-2.5 px-4 items-center gap-2.5 rounded-lg justify-center"
        [ngClass]="{
          'border-green-primary bg-green-primary primary-btn shadow-button-primary':
            neutral,
          'bg-red-primary border-red-primary': !neutral
        }"
      >
        <span
          class="text-white-primary leading-4 font-medium text-sm capitalize"
        >
          {{ confirmText }}</span
        >
      </button>
    </div>
  </div>
</div>
